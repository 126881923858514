.navbar-navlinks {
  display: flex;
  justify-content: space-between;
  font-family: "Lato";
  flex-grow: 1;
}

.navbar-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  cursor: pointer;
}

.navbar-icons {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.navbar-title-link {
  text-decoration: none;
  font-family: "Lato";
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}

.navbar-link {
  border-bottom: 3px solid RGBA(0, 0, 0, 0);
  margin-left: 60px;
  text-decoration: none;
  color: white;
  font-size: 25px;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
  &:active {
    color: rgb(204, 203, 203);
    transform: scale(1.05);
  }
}
