.orar-page {
    display: flex;
    justify-content: center;
    margin-top: 3%;
}

.orar-page-container  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 3%;
}

.orar-page-content {
    display: flex;
    justify-content: center;
}

.loading-orar-page {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.orar-page-buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 3% 3%;
}

.user-buttons {
    display: flex;
    align-items: center;
}

@media (max-width: 900px) {
    .orar-page-buttons-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .user-buttons {
        width: 100%;
        justify-content: space-between;
    }
}   