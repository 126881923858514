.drawer-link {
    text-decoration: none;
    color: #fff;
    font-family: "Lato";
    font-size: 25px;
    &:active {
        color:rgb(204, 203, 203);
    }
}
    


