.facultati-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
    grid-gap: 20px 60px;
}

.facultati-layout {
    display: flex;
    justify-content: center;
}

.loading-facultati {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-facultate-added {
    text-align: center;
    margin-top: 10% !important;
}

@media (max-width: 1475px) {
    .facultati-cards {    
        grid-template-columns: 1fr 1fr 1fr;
    }
}   

@media (max-width: 1100px) {
    .facultati-cards { 
        grid-template-columns: 1fr 1fr 
    }
}   

@media (max-width: 775px) {
    .facultati-cards { 
        grid-template-columns: auto; 
    }
}   