.administrare-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.administrare-cards {
    height: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    grid-gap: 20px 60px;
}

.administrare-title {  
    text-align: center;
    margin-bottom: 7% !important;
}

.administrare-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;
}

@media (max-width: 1100px) {
    .administrare-cards {  grid-template-columns: auto;}
}   