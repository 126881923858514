.facultate-card {
  width: 300px;
  margin: 9%;
}

.facultate-card:hover {
  transform: scale(1.05);
}

.facultate-card-content {
  display: flex;
  flex-direction: column;
}
