.statistici-page {
    display: flex;
    justify-content: center;
}

.statistici-pies {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    grid-gap: 100px 60px;
}

.statistici-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2%;
}

.statistici-title {
    text-align: center;
}

.loading-statistici-page {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.statistici-pie{
    width: 350px; 
}

.statistici-bar{
    display: flex;
    align-items: center;
    width: 400px; 
}

@media (max-width: 1400px) {
    .statistici-pies {  
        grid-template-columns: 1fr 1fr;
    }
}   

@media (max-width: 1100px) {
    .statistici-pies {  
        grid-template-columns: auto; 
    }

    .statistici-pie {
        width: 300px; 
    }

    .statistici-bar {
        width: 330px; 
    }
} 

@media (min-width: 1600px) {
    .statistici-layout {
        justify-content: center;
    }
}   