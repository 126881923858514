.cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
    grid-gap: 20px 60px;
}

.cards-layout {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 3%;
}

.cards-page-buttons-container {
    display: flex;
    justify-content: flex-end;
    margin: 0px 5%;
}

.no-card-added {
    text-align: center;
    margin-top: 10% !important;
}

.loading-cards-page {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1475px) {
    .cards {    
        grid-template-columns: 1fr 1fr 1fr;
    }
}   

@media (max-width: 1100px) {
    .cards { 
        grid-template-columns: 1fr 1fr 
    }
}   

@media (max-width: 775px) {
    .cards { 
        grid-template-columns: auto; 
    }
}   