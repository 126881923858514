.basic-modal-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    max-height: 85%;
    overflow-y: auto;
    text-align: center;
}

@media (max-width: 1000px) {
    .basic-modal-wrapper {  width: 90%; }
}   