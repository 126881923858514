.option-card-layout {
    display: flex;
    flex-direction: column;
    margin: 10% 0px;
}

.option-card-action-area {
    height: 100%;
}

.option-card-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}