.tab-content {
    display:flex;
    flex-direction: column;
    justify-content:center;
    width: 100%;
    margin-top: 3%;
}

.loading-filters-page {
    margin-top: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
}